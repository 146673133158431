<template>
  <div>
    <nav-bar-component></nav-bar-component>
    <router-view class="container pt-3 pb-3"></router-view>
  </div>
</template>
  
<script>
    
  import NavBarComponent from '@/layout/components/NavBarComponent.vue';

  export default {
    name: 'WebsiteLayout',
    components : {
      NavBarComponent
    },
    data() {
      return {
        showSidebar: false
      }
    },
    props: {
    },
    async created() {

    },
    methods : {
    }
  }
</script>
    
<style >

</style>
    