import { render, staticRenderFns } from "./NoteComponent.vue?vue&type=template&id=6ede31cd&scoped=true"
import script from "./NoteComponent.vue?vue&type=script&lang=js"
export * from "./NoteComponent.vue?vue&type=script&lang=js"
import style0 from "./NoteComponent.vue?vue&type=style&index=0&id=6ede31cd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ede31cd",
  null
  
)

export default component.exports