<template>
    <div>
        <div>
          <notes-group-component></notes-group-component>
        </div>
    </div>
  </template>
  
  <script>

  import NotesGroupComponent from '@/components/home/NotesGroupComponent.vue';
  export default {
    name: 'HomeView',
    components:{
      NotesGroupComponent
    },
    data() {
      return {

      };
    },
    async created(){

    }
  }
  </script>
  
  <style scoped>
 
  </style>
  