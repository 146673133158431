<template>
    <div>
        <div v-if="noteReminders.length > 0" class="list-group">
            <div v-for="reminder, idx in noteReminders" :key="idx" class="list-group-item list-group-item-action" aria-current="true">
                <reminder-component :reminder="reminder" :count="idx + 1"></reminder-component>
            </div>
        </div>
        <div v-else class="alert alert-info alert-sm p-0" role="alert">
            <span class="alert-icon"><span class="visually-hidden">Info</span></span>
            <p>This note doesn't have any reminders configured yet.</p>    
        </div>
    </div>

  </template>
  
  <script>
  
  import ReminderComponent from './ReminderComponent.vue';

  export default {
    name: 'RemindersComponent',
    props: {
      noteReminders: {
        type: Array,
        default() {
            return []
        }
      }
    },
    components: {
        ReminderComponent
    },
    methods: {
    }
  }
  </script>
  
  <style scoped>

  </style>
  
