import Axios from 'axios'

const actions = {

  // ////////////////////////////////////////////
  // Public Actions
  // ////////////////////////////////////////////

  async get({commit, dispatch}, payload) {
    commit(`${payload.storeName}/SET_ERROR`, null)
    if (payload.needLoading) {
      commit(`${payload.storeName}/SET_LOADING_STATUS`, true)
    }
    return new Promise((resolve, reject) => {   
      Axios.get(payload.url)
        .then(response => {
          if (payload.dispatch_Func) {
            if (payload.dispatch_data) {
              dispatch(payload.dispatch_Func, payload.dispatch_data)
            }else {
              dispatch(payload.dispatch_Func)
            }
          } else if (payload.commitUrl) {
            commit(payload.commitUrl, response.data.data)
          }
          resolve(response)
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error.response.data, "message")) {
            commit(`${payload.storeName}/SET_ERROR`, error.response.data.message)
            reject(error.response.data.message)
          }
        }).finally(() => {
        if (payload.needLoading) {
          commit(`${payload.storeName}/SET_LOADING_STATUS`, false)
        }
      })
    })
  },

  async add({commit, dispatch}, payload) {
    commit(`${payload.storeName}/SET_ERROR`, null)
    if (payload.needLoading) {
      commit(`${payload.storeName}/SET_LOADING_STATUS`, true)
    }

    return new Promise((resolve, reject) => {
      
      if (payload.hasFormData) {       
        // console.log(payload)
        // for (const [key, value] of payload.formData) {
        //   console.log('»', key, value)
        // }
        // console.log(...payload.formData)
        Axios.post('/' + payload.url, payload.formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((res)=>{
            if (payload.dispatch_Func) {
              if (payload.dispatch_data) {
                dispatch(payload.dispatch_Func, payload.dispatch_data)
              }else {
                dispatch(payload.dispatch_Func)
              }
            } else if (payload.commitUrl) {
              commit(payload.commitUrl, res.data.data)
            }
            resolve(res)
          })
          .catch((err)=>{
            if (Object.prototype.hasOwnProperty.call(err.response.data, "message")) {
              commit(`${payload.storeName}/SET_ERROR`, err.response.data.message)
              reject(err.response.data.message)
            }       
          })
          .finally(() => {
            if (payload.needLoading) {
              commit(`${payload.storeName}/SET_LOADING_STATUS`, false)
            }
          })
      }else { 
        //if payload.hasFormData == false
        // for (const [key, value] of payload.form.dateRange) {
        //   console.log('»', key, value)
        // }
        Axios.post('/' + payload.url, payload.form)
        .then((res)=>{
          if (payload.dispatch_Func) {
            if (payload.dispatch_data) {
              dispatch(payload.dispatch_Func, payload.dispatch_data)
            }else {
              dispatch(payload.dispatch_Func)
            }
          } else if (payload.commitUrl) {
            commit(payload.commitUrl, res.data.data)
          }
          resolve(res)
        })
        .catch((err)=>{
          if (Object.prototype.hasOwnProperty.call(err.response.data, "message")) {
            commit(`${payload.storeName}/SET_ERROR`, err.response.data.message)
            reject(err.response.data.message)
          }       
        })
        .finally(() => {
          if (payload.needLoading) {
            commit(`${payload.storeName}/SET_LOADING_STATUS`, false)
          }
        })
      }

    })
  },

  async edit({commit, dispatch}, payload) {
    commit(`${payload.storeName}/SET_ERROR`, null)
    if (payload.needLoading) {
      commit(`${payload.storeName}/SET_LOADING_STATUS`, true)
    }
    return new Promise((resolve, reject) => {
      let response;
      if (payload.hasFormData) {
        response = Axios.put('/' + payload.url, payload.formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
      }else {
        response = Axios.put('/' + payload.url, payload.form)
      }
      response.then(response => {
        if (payload.dispatch_Func) {
          if (payload.dispatch_data) {
            dispatch(payload.dispatch_Func, payload.dispatch_data)
          }else {
            dispatch(payload.dispatch_Func)
          }
        } else if (payload.commitUrl) {
          commit(payload.commitUrl, response.data.data)
        }
        resolve(response)
      }).catch(error => {
        if (Object.prototype.hasOwnProperty.call(error.response.data, "message")) {
          commit(`${payload.storeName}/SET_ERROR`, error.response.data.message)
          reject(error.response.data.message)
        }
      }).finally(() => {
        if (payload.needLoading) {
          commit(`${payload.storeName}/SET_LOADING_STATUS`, false)
        }
      })
    })
  },

  async partialEdit({commit, dispatch}, payload) {

    commit(`${payload.storeName}/SET_ERROR`, null)
    if (payload.needLoading) {
      commit(`${payload.storeName}/SET_LOADING_STATUS`, true)
    }
    return new Promise((resolve, reject) => {
      let response;
      
      if (payload.hasFormData) {
        response = Axios.patch('/' + payload.url, payload.formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
      }else {
        response = Axios.patch('/' + payload.url, payload.form)
      }
      response.then(response => {
        if (payload.dispatch_Func) {
          if (payload.dispatch_data) {
            dispatch(payload.dispatch_Func, payload.dispatch_data)
          }else {
            dispatch(payload.dispatch_Func)
          }
        } else if (payload.commitUrl) {
          commit(payload.commitUrl, response.data.data)
        }
        resolve(response)
      }).catch(error => {
        if (Object.prototype.hasOwnProperty.call(error.response.data, "message")) {
          commit(`${payload.storeName}/SET_ERROR`, error.response.data.message)
          reject(error.response.data.message)
        }
      }).finally(() => {
        if (payload.needLoading) {
          commit(`${payload.storeName}/SET_LOADING_STATUS`, false)
        }
      })
    })
  },

  async delete({commit, dispatch}, payload) {
    if(confirm("Are you sure ?")){
      commit(`${payload.storeName}/SET_ERROR`, null)

      if (payload.needLoading) {
        commit(`${payload.storeName}/SET_LOADING_STATUS`, true)
      }
    
      return new Promise((resolve, reject) => {
        let response = Axios.delete(`/`+ payload.url +`/${payload.id}`)
        response.then(response => {
          if (payload.dispatch_Func) {
            if (payload.dispatch_data) {
              dispatch(payload.dispatch_Func, payload.dispatch_data)
            }else {
              dispatch(payload.dispatch_Func)
            }
          } else if (payload.commitUrl) {
            commit(payload.commitUrl, response.data.data)
          }
          resolve(response)
        }).catch(error => {
          if (Object.prototype.hasOwnProperty.call(error.response.data, "message")) {
            commit(`${payload.storeName}/SET_ERROR`, error.response.data.message)
            reject(error.response.data.message)
          }
        }).finally(() => {
          if (payload.needLoading) {
            commit(`${payload.storeName}/SET_LOADING_STATUS`, false)
          }
        })
      })
    }
    else{
      return new Promise((resolve)=>{
        resolve(
            {
              success: 'job_cancelled',
              data:{
                message: 'Cancelled'
              }
            }
          )
      })
    }
  },

  async restore({dispatch}, payload) {
    if(confirm("Are you sure ?")){
      try {
        await Axios.get(`/`+ payload.url +`/${payload.id}/restore`)
        if (payload.dispatch_data) {
          dispatch(payload.dispatch_Func, payload.dispatch_data)
        }else {
          dispatch(payload.dispatch_Func)
        }
      } catch (error) {
        console.log(error)
      }
    }
  },

  
  async getLocalItem({commit}, payload) {
    commit(payload.commitUrl, payload.id)
  },

  async changeFormStatus({commit}, payload) {
    commit(payload.commitUrl, payload.status)
  },

  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////
  updateSidebarWidth({ commit }, width) {
    commit('UPDATE_SIDEBAR_WIDTH', width);
  },
  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY');
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val);
  },
  updateWindowWidth({ commit }, width) {
    commit('UPDATE_WINDOW_WIDTH', width);
  }
}

export default actions
