<template>
<div>
  <router-view :key="$route.fullPath"></router-view>
</div>

</template>

<script>
// import { Button } from 'boosted'

export default {
  name: 'App',
  data() {
    return {
      alertVisible: false, // Controls whether the alert is shown
    };
  },
  components: {
    // 'btest': Button
  },
  methods: {
    showAlert() {
      this.alertVisible = true; // Show the alert when the button is clicked
    },
    dismissAlert() {
      this.alertVisible = false; // Dismiss the alert when the close button is clicked
    },
  },
};
</script>

<style>

@import 'boosted/dist/css/orange-helvetica.min.css';
@import 'boosted/dist/css/boosted.min.css';


/* main.css overrides all prev css files */
@import '@/assets/styles/main.css';

</style>
