<template>
  <!-- Modal -->
  <div class="modal " id="newNoteReminderModal" tabindex="-1" aria-labelledby="newNoteReminderModal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title h5" id="newNoteReminderModal">{{ isEditing ? 'Edit' : 'Create' }} a reminder for the note: <span style="text-decoration: underline;">{{ note ? note.title : null}}</span></h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Close">
            <span class="visually-hidden">Close</span>
          </button>
        </div>
        <div class="modal-body mt-3">
          <div class="mb-3">
            <label for="exampleInputDate1" class="form-label">Choose Date:</label>
            <input type="date" class="form-control" id="exampleInputDate1" v-model="noteReminderObject.form.date">
          </div>
          
          <div class="mb-3">
            <label for="inputSelect1" class="form-label">Number of emails</label>
            <select class="form-select" v-model="numberOfEmails">
              <option v-for="i in 10" :key="i" :value="i">{{ i }}</option>
            </select>
          </div>

          <div class="emails-container">
            <div class="mt-1" v-for="j in numberOfEmails" :key="`email${j}`">
              <label class="form-label">Email {{ j }} *</label>
              <input type="email" placeholder="abc@gmail.com" class="form-control" v-model="noteReminderObject.form.recipients[j-1]">
            </div>
          </div>

          <div class="alert alert-danger mt-3" role="alert" v-if="error">
            <span class="alert-icon"><span class="visually-hidden">Error</span></span>
            <p>{{ error }}</p>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="saveNoteReminder">{{ isEditing ? 'Update' : 'Save' }} Reminder</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import apiClient from '@/apiClient';

export default {
  name: 'SetNoteModel',
  props: {
    noteReminder: {
      type: Object,
      default: null
    },
    note: {
      type: Object,
      default: null
    },
    isEditing : {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modelEl: null,
      error: null,
      numberOfEmails: null,
      noteReminderObject: {
        form: {
          date: null,
          recipients: []
        }
      }
    };
  },
  methods: {
    async saveNoteReminder() {
      this.error = null;
      
      /*Start Validations*/
      if(!this.noteReminderObject.form.date){
        this.error = "Please choose a reminder date!";
        return ;
      }

      if(this.noteReminderObject.form.recipients.length == 0){
            this.error = "Please fill in the email field(s)!";
      }else{
        for(let i = 0; i < this.noteReminderObject.form.recipients.length; i++){
          if(!this.noteReminderObject.form.recipients[i]){
            this.error = "Please fill in the email field(s)!";
            return ;
          }
        }
      }
      /*End Validations*/

      if(!confirm("Are you sure you want to save this reminder?"))
        return ;

      try {
        // Create a new note reminder
        let data = await apiClient.post(`notes/${this.note.id}/reminders`, this.noteReminderObject.form);
        this.$emit('note-reminder-saved', data.reminder, this.note.id);
        $('#newNoteReminderModal').modal('hide');
      } catch (e) {
        this.error = e;
        console.log(e);
      }
    },
    resetForm() {
      this.noteReminderObject.form.date = null;
      this.noteReminderObject.form.recipients = [];
      this.numberOfEmails = 1;
      this.error = null;
    }
  },
  created(){
    this.numberOfEmails = 1;
  },
  mounted() {
    this.modelEl = document.getElementById('newNoteReminderModal');

    /* eslint-disable no-unused-vars */
    this.modelEl.addEventListener('hidden.bs.modal', event => {
      this.resetForm();
    });

    /*
    this.modelEl.addEventListener('shown.bs.modal', event => {
      console.log("Modal shown event", event);
    });
    */
  },
  watch: {
    'note': function(newVal){
      console.log(newVal)
      this.resetForm();
    },
    'numberOfEmails': function(newVal){
      this.noteReminderObject.form.recipients = [];
      for(let i = 0; i < newVal; i++)
        this.noteReminderObject.form.recipients.push('');
    }
  }
};
</script>

<style scoped>
.emails-container{
  max-height: 250px;
  overflow: auto;
}
</style>
