export function middlewarePipeline(context, middleware, index) {
    const nextMiddleware = middleware[index];
  
    if (!nextMiddleware) {
      return context.next;
    }
  
    return (nextArgs) => {
      if (nextArgs) {
        return context.next(nextArgs);
      }
  
      const nextPipeline = middlewarePipeline(context, middleware, index + 1);
  
      nextMiddleware({ ...context, next: nextPipeline });
    };
  }
  