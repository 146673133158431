<template>
    <div>
        <div class="d-flex w-100 justify-content-between">
            <div class="mb-1">Reminder <span style="padding: 0 5px;" class="text-bg-secondary">{{ count }}</span></div>
            <small ><span>Reminder date:</span> <span><b>{{ reminder.date_formatted.date }}</b> at <b>{{ reminder.date_formatted.time }}</b></span></small>
        </div>
        <div class="mt-1 mb-1">Status: <span style="padding: 2px 8px;" :class="getStatusClass">{{ reminder.status }}</span></div>
    </div>

  </template>
  
  <script>
  
  export default {
    name: 'ReminderComponent',
    props: {
      reminder: {
        type: Object
      },
      count: {
        type: Number
      }
    },
    computed :{
        getStatusClass(){
            if(this.reminder.status == 'completed')
                return 'text-bg-success';
            else if(this.reminder.status == 'active')
                return 'text-bg-warning';
            return 'text-bg-danger';
        }
    },
    methods: {
    }
  }
  </script>
  
  <style scoped>

  </style>
  
