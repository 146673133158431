<template>
    <div>
      <nav class="navbar navbar-expand-lg nav-container-custom" data-bs-theme="dark" aria-label="Navbar Notes">
        
      <!-- Orange brand logo -->
      <div class="navbar-brand">
        <img src="@/assets/images/OBS-logo.svg" width="50" height="50" alt="Boosted - Back to Home" loading="lazy">
      </div>

      <!-- Burger menu (visible on small screens) -->
      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target=".global-header-1" aria-controls="global-header-1.1 global-header-1.2" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

        <!-- Navbar with links -->
        <div id="global-header-1.1" class="navbar-collapse collapse me-lg-auto global-header-1">
          <ul class="navbar-nav ">
            <li class="nav-item">
                <router-link :class="['nav-link', { 'active': this.$route.path == '/' }]" to="/">
                  Home
                </router-link>
            </li>
            <li class="nav-item">
              <!-- <a href="#" class="nav-link"> -->
              <router-link :class="['nav-link', { 'active': isActive('/about-us') }]" to="/about-us">
              About us
            <!-- </a> -->
            </router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NavBarComponent',
    props: {

    },
    computed: {

    },
    methods: {
      isActive(route) {
        return this.$route.path.startsWith(route);
      },
    }
  }
  </script>
  
  <style scoped>

.nav-container-custom{
  align-items: flex-end;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
  