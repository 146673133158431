import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import store from '../store/store'
import { middlewarePipeline } from './middlewarePipeline';


import websiteRouter from './modules/website.js';

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    ...websiteRouter,
  ]
})

router.beforeEach(async (to, from, next) => {
  store.commit('setLoading', true); // Start loading
  
  // the meta properties are inherited in children
  const matchedMeta = to.matched.reduce((acc, record) => {
    return { ...acc, ...record.meta };
  }, {});

  document.title = matchedMeta.pageTitle || 'Note App';

  if (!matchedMeta.middleware) {
    return next();
  }

  const middleware = matchedMeta.middleware
  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })

})

/* eslint-disable no-unused-vars */
router.afterEach((to, from) => {
  // store.commit('setLoading', false); // Stop loading once the route is loaded
});

export default router;
