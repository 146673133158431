<template>
  <div>
    <div class="d-flex flex-row justify-content-between align-items-center mb-3">
      <h1>Number of Notes: <span class="badge text-bg-secondary">{{ notes.length }}</span></h1>
      <div class="add-icon-container" @click="openNoteModal(false)">
        <img src="@/assets/images/add.svg" class="add-icon"/>
      </div>
    </div>
    
    <set-note-modal :is-editing="isEditing" :note="selectedNote" @note-saved="addNewNote" @note-updated="updateNote" ref="newNoteModal"></set-note-modal>
    <set-reminder-modal :note="selectedNote" @note-reminder-saved="addNewNoteReminder"></set-reminder-modal>
    <div class="notes-container">
      <note-component v-for="note, idx in notes" :key="idx" 
        class="note-component" 
        :note="note"
        @note-deleted="deleteNote" 
        @open-update-modal="(note) => {openNoteModal(true, note)}"
        @open-create-reminder-modal="(note) => {openNoteReminderModal(note)}">
      </note-component>  
    </div>

  </div>
</template>

<script>
import $ from 'jquery';
import apiClient from '@/apiClient';

import NoteComponent from './NoteComponent.vue';
import SetNoteModal from './SetNoteModal.vue';
import SetReminderModal from './SetReminderModal.vue';

export default {
  name: 'NotesGroupComponent',
  data() {
    return {
      isEditing: false,
      selectedNote: null,
      notes: []
    };
  },
  components: {
    NoteComponent,
    SetNoteModal,
    SetReminderModal
  },
  methods: {
    openNoteModal(isEditing, note = null) {
      // this.$refs.newNoteModal.isEditing = isEditing;
      // this.$refs.newNoteModal.note = note;
      this.isEditing = isEditing;
      this.selectedNote = note;

      $('#newNoteModal').modal('show');
    },
    openNoteReminderModal(note = null) {
      this.selectedNote = note;
      $('#newNoteReminderModal').modal('show');
    },
    addNewNote(note) {
      this.notes.push(note);
    },
    addNewNoteReminder(reminder, noteId){
      const noteIndex = this.notes.findIndex(note => note.id === noteId);
      this.notes[noteIndex].NoteReminders.push(reminder);
    },
    updateNote(updatedNote){
      // Find the index of the note that needs to be updated
      const noteIndex = this.notes.findIndex(note => note.id === updatedNote.id);
      this.notes.splice(noteIndex, 1, updatedNote);

    },
    deleteNote(noteId){
      this.notes = this.notes.filter(note => note.id !== noteId);
    }
  },
  async created(){
    let res = await apiClient.get('/notes');
    this.notes = res.notes;
  }
}
</script>

<style scoped>
  .notes-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .note-component{
    margin: 10px;
  }

  .add-icon-container{
    background-color: black;
    border: 1px solid transparent;
    border-radius: 10px;
    padding:5px;
    transition: all 0.1s;
  }
  .add-icon{
    width: 30px;
    height: 30px;
  }

  .add-icon-container:hover{
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #fd7a00;
  }
</style>
