import axios from 'axios';

// Create an Axios instance
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'http://localhost:3000',
  timeout: process.env.VUE_APP_API_TIMEOUT || 10000, // Set a timeout for requests
});



// response interceptor
apiClient.interceptors.response.use(
  (response) => {
    const res = response.data.data;
    if (res.status !== 200) {
      // handle unsuccessful response
    }
    return res;
  },
  (error) => {
    // handle unsuccessful promise
    let errorMessage = error.response.data.message
    return Promise.reject(errorMessage);  // Return the error so the caller can handle it
  }
);

// API Methods for GET, POST, PUT, PATCH, DELETE
export default {
  get(url, params = {}) {
    return apiClient.get(url, { params });
  },
  post(url, data) {
    return apiClient.post(url, data);
  },
  patch(url, data) {
    return apiClient.patch(url, data);
  },
  put(url, data) {
    return apiClient.put(url, data);
  },
  delete(url) {
    return apiClient.delete(url);
  }
};
