import WebsiteLayout from '@/layout/WebsiteLayout.vue'
import HomeView from '@/views/HomeView.vue';
import AboutUsView from '@/views/AboutUsView.vue';

let websiteRouter = [
  { 
    path: '/', 
    component: WebsiteLayout,
    children: [
        { 
            name: 'Home',
            path: '/', 
            component: HomeView 
        },
        { 
            name: 'AboutUs',
            path: '/about-us', 
            component: AboutUsView 
        }
    ]
  }
];


export default websiteRouter;
