<template>
  <!-- Modal -->
  <div class="modal " id="newNoteModal" tabindex="-1" aria-labelledby="newNoteModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title h5" id="newNoteModalLabel">{{ isEditing ? 'Edit' : 'Create' }} a note</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Close">
            <span class="visually-hidden">Close</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="inputTitle1" class="form-label">Title</label>
            <input type="text" class="form-control" id="inputTitle1" v-model="noteObject.form.title">
          </div>
          <div>
            <label for="inputDescription1" class="form-label">Description</label>
            <textarea class="form-control" rows="4" cols="50" id="inputDescription1" v-model="noteObject.form.description"></textarea>
          </div>

          <div class="alert alert-danger mt-3" role="alert" v-if="error">
            <span class="alert-icon"><span class="visually-hidden">Error</span></span>
            <p>{{ error }}</p>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="saveNote">{{ isEditing ? 'Update' : 'Save' }} Note</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import apiClient from '@/apiClient';

export default {
  name: 'SetNoteModel',
  props: {
    note: {
      type: Object,
      default: null
    },
    isEditing : {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modelEl: null,
      error: null,
      noteObject: {
        url: 'notes',
        form: {
          title: null,
          description: null
        }
      }
    };
  },
  methods: {
    async saveNote() {
      try {
        if (this.isEditing) {
          if(confirm("Are you sure you want to update this note?")){
            // Update existing note
            let data = await apiClient.patch(`${this.noteObject.url}/${this.note.id}`, this.noteObject.form);
            this.$emit('note-updated', data.note);
            $('#newNoteModal').modal('hide');
          }
        } else {
          if(confirm("Are you sure you want to create a new note?")){
            // Create a new note
            let data = await apiClient.post(this.noteObject.url, this.noteObject.form);
            this.$emit('note-saved', data.note);
            $('#newNoteModal').modal('hide');
          }
        }
      } catch (e) {
        this.error = e;
        console.log(e);
      }
    },
    resetForm() {
      this.noteObject.form.title = this.note ? this.note.title : null;
      this.noteObject.form.description = this.note ? this.note.description : null;
      this.error = null;
    }
  },

  mounted() {
    this.modelEl = document.getElementById('newNoteModal');
    
    /* eslint-disable no-unused-vars */
    this.modelEl.addEventListener('hidden.bs.modal', event => {
      this.resetForm();
    });
    /*
    this.modelEl.addEventListener('shown.bs.modal', event => {
      console.log("Modal shown event", event);
    });
    */
  },
  watch: {
    'note': function(newVal){
      console.log(newVal)
      this.resetForm();
    }
  }
};
</script>

<style scoped>
</style>
