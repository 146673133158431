<template>
    <div>
        This is about us page
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutUsView'
  }
  </script>
  
  <style scoped>
 
  </style>
  