<template>
  <div>
    <div class="card custom-card-container">
      <div class="d-flex flex-col align-items-center justify-content-between">
        <div>
          <h5 class="card-title"> {{ note.title }}  </h5>
        </div>

        <div class="d-flex flex-row justify-content-center gap-2">
          <div class="delete-icon-container" @click="openCreateNoteReminderModal()">
            <img src="@/assets/images/clock.svg" class="delete-icon"/>
          </div>
          <div class="delete-icon-container" @click="openUpdateNoteModal()">
            <img src="@/assets/images/pencil.svg" class="delete-icon"/>
          </div>
          <div class="delete-icon-container" @click="deleteNote()">
            <img src="@/assets/images/trash.svg" class="delete-icon"/>
          </div>
        </div>
      </div>
      <div class="card-body">
        <p class="card-text"> {{ note.description }} </p>
        <h6 class="mb-2">Reminders ({{ note.NoteReminders.length }})</h6>
        <reminders-component :note-reminders="note.NoteReminders"></reminders-component>
      </div>


    </div>
  </div>
</template>

<script>
import apiClient from '@/apiClient';
import RemindersComponent from './RemindersComponent.vue';
export default {
  name: 'NoteComponent',
  props: {
    note: {
      type: Object
    }
  },
  components:{
    RemindersComponent
  },
  methods: {
    openUpdateNoteModal(){
      this.$emit('open-update-modal', this.note);
    },
    openCreateNoteReminderModal(){
      this.$emit('open-create-reminder-modal', this.note);
    },
    async deleteNote(){
      if(confirm("Are you sure you want to delete this note?")){
        try{
          await apiClient.delete(`notes/${this.note.id}`)
          this.$emit('note-deleted', this.note.id);
        }catch(e){
          console.log(e)
        }
      }

    }
  }
}
</script>

<style scoped>

.custom-card-container{
  width: 600px;
  padding: 20px;
}
.card-body{
  padding: 0;
}
.delete-icon-container{
    background-color: #fd7a00;
    border: 1px solid transparent;
    border-radius: 10px;
    padding:5px;
    transition: all 0.2s;
  }

  .delete-icon-container:hover{
    cursor: pointer;
    transform: scale(1.1);
  }
.delete-icon{
  width: 30px;
  height: 30px;
}

</style>
